<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <h3>จัดการสินค้า</h3>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4" class="mr-4">
            <v-text-field
              class="mb-4"
              v-model="search"
              dense
              hide-details
              outlined
              placeholder="ค้นหา"
            ></v-text-field>
          </v-col>
          <v-spacer></v-spacer>
          <!-- <v-col class="text-right">
            <v-btn @click="goToCreate()" color="primary" dark
              >เพิ่มสินค้า</v-btn
            >
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card
          ><v-data-table
            :headers="headersproduct"
            :items="items"
            :search="search"
            :items-per-page="10"
            class="elevation-1"
          >
            <template v-slot:[`item.mainImg`]="{ item }">
              <v-img :src="item.mainImg" width="100px" style="margin: 10px;"></v-img>
            </template>
            <template v-slot:[`item.activeFlag`]="{ item }">
              <span v-if="item.activeFlag == '0'" style="color: #f00;">ยังไม่อนุมัติ</span>
              <span v-if="item.activeFlag == '1'" style="color: #44ad43;">อนุมัติแล้ว</span>
              <!-- <span v-if="item.activeFlag == '2'">ตีกลับ</span> -->
            </template>
            <template v-slot:[`item.action`]="{ item }">
              <v-row justify="center">
                <!-- <v-icon @click="viewProduct(item)">mdi-eye</v-icon> -->
                <v-icon @click="UpdateProduct(item)" class="mx-2"
                  >mdi-pencil</v-icon
                >
                <v-icon @click="DeleteProduct(item)">mdi-delete</v-icon>

                <!-- <v-col cols="4" class="mx-0 px-1"
              ><v-btn @click="GetQuestions(item)" color="#833133" dark
                ><v-icon>mdi-magnify</v-icon></v-btn
              ></v-col
            > -->
              </v-row>
            </template>
          </v-data-table></v-card
        >
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { Encode, Decode } from "@/services";
export default {
  data() {
    return {
      headersproduct: [
        { text: "ลำดับ", value: "count", align: "center" },
        { text: "รูปสินค้า", value: "mainImg", align: "center" },
        { text: "รหัสสินค้า", value: "productNumber", align: "center" },
        {
          text: "ชื่อสินค้า",
          value: "productName",
          align: "center",
          width: "300",
        },
        { text: "ประเภทสินค้า", value: "productType", align: "center", width: "200" },
        { text: "ไลน์", value: "lineId", align: "center" },
        { text: "ค่าเช่า", value: "rentPrice", align: "center", width: "200" },
        { text: "อนุมัติ", value: "activeFlag", align: "center", width: "200" },
        { text: "ตัวเลือก", value: "action", align: "center", width: "120" },
      ],
      // activeFlag: (...)
      // count: 1
      // createdAt: (...)
      // description: (...)
      // id: (...)
      // lineId: (...)
      // mainImg: (...)
      // productName: (...)
      // productNumber: (...)
      // productType: (...)
      // province: (...)
      // rentPrice: (...)
      // updatedAt: (...)
      // user: (...)
      // userId: (...)
      items: [],
      search: "",
      count: 0,
    };
  },
  created() {
    this.getAllProduct();
    for (let i in this.items) {
      this.items[i].count = parseInt(i) + 1;
      //console.log(this.list);
    }
  },

  methods: {
    async getAllProduct() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/products/findAllAdmin`
        // 'http://localhost:8081/types/'
      );
      console.log("getAllproducts", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
    },

    goToCreate() {
      this.$router.push("createproduct");
    },
    viewProduct(val) {
      localStorage.setItem("productdata", Encode.encode(val));
      this.$router.push("viewProduct");
    },
    UpdateProduct(val) {
      localStorage.setItem("productdata", Encode.encode(val));
      this.$router.push("EditProduct");
    },
    async DeleteProduct(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userForrent"))
          );
          const auth = {
            headers: { Authorization: `Bearer ${user.token}` },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/products/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllProduct();
        }
      });
    },
  },
};
</script>